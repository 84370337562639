<template>
  <div class="banner anime">
    <img
      v-if="isShowBanner"
      src="https://static.yizao2025.com/haodatoxiao.gif"
      alt=""
    />
    <div class="banner-main">
      <div class="content">
        <div class="logo">
          <img src="@/assets/images/public/c_logo.png" alt="" />
        </div>
        <div class="title">
          <img src="@/assets/images/public/c_banner.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowBanner: true,
    };
  },
  methods: {
    imgLoad() {
      setTimeout((_) => {
        this.isShowBanner = false;
      }, 1700);
    },
  },
  mounted() {
    this.imgLoad();
  },
};
</script>
<style lang="scss" scoped>
.banner {
  background: url("../../../assets/images/public/c_banner.jpg") no-repeat center;
  background-size: auto 100%;
  height: 39vw;
  background-color: #090830;
  justify-content: center;
  display: flex;
  overflow: hidden;
  & > img {
    height: 100%;
    display: block;
  }
  .banner-main {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    .content {
      width: 65vw;
      position: relative;
      min-width:auto;
      height: 100%;
      .logo {
        position: absolute;
        left: 0;
        top: 2vw;
        width: 40%;
        img{
          max-width: 100%;
        }
      }
      .title {
        position: absolute;
        left: 15%;
        bottom: 4vw;
        width: 70%;
        display: flex;
        justify-content: center;
        img{
          max-width: 100%;
        }
      }
    }
  }
}
</style>