<template>
  <div class="competition">
    <banner />
    <div class="show-list">
      <div class="content">
        <div class="c-title anime">
          作品展示
          <span></span>
        </div>
        <div class="filter anime">
          <el-dropdown @command="yChange">
            <el-button type="primary">
              {{ year.dictLabel }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu class="filter-list" slot="dropdown">
              <el-dropdown-item
                :command="item.dictValue"
                v-for="item in yz_vote_year"
                :key="item.dictValue"
                >{{ item.dictLabel }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="gChange">
            <el-button type="primary">
              {{ university.dictLabel }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu class="filter-list" slot="dropdown">
              <el-dropdown-item
                :command="item.dictValue"
                v-for="item in yz_university"
                :key="item.dictValue"
                >{{ item.dictLabel }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="lChange">
            <el-button type="primary">
              {{ type.dictLabel
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu class="filter-list" slot="dropdown">
              <el-dropdown-item
                :command="item.dictValue"
                v-for="item in yz_vote_award_type"
                :key="item.dictValue"
                >{{ item.dictLabel }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="jChange">
            <el-button type="primary">
              {{ award.dictLabel
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu class="filter-list" slot="dropdown">
              <el-dropdown-item
                :command="item.dictValue"
                v-for="item in yz_vote_award"
                :key="item.dictValue"
                >{{ item.dictLabel }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="list">
          <a
            :href="`/competition/showDetail?id=${item.id}`"
            class="item anime"
            v-for="item in datalist"
            :key="item.id"
          >
            <el-image :src="item.firstImage" fit="cover"></el-image>
            <div class="info">
              <h3>{{ item.worksName }}</h3>
              <div class="b">
                <p>
                  <i class="iconfont icon-liulan"></i>{{ item.viewCount || 0 }}
                </p>
                <p>
                  <i class="iconfont icon-dianzan"></i>{{ item.ticket || 0 }}
                </p>
                <p>
                  <i class="iconfont icon-pinglun"></i
                  >{{ item.messageCount || 0 }}
                </p>
                <div class="bshare-custom">
                  <share
                    :url="`https://www.yizao2025.com/competition/showDetail?id=${item.id}`"
                    :image="item.firstImage"
                    :title="item.worksName"
                    :desc="item.decoration"
                    :id="item.id"
                  >
                    <a title="分享"><i class="iconfont icon-fenxiang"></i></a>
                  </share>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="loading" v-if="loading != 0">
          <p v-if="loading == 1"><span class="el-icon-loading"></span>加载中</p>
          <p v-if="loading == 2">暂无数据</p>
        </div>
        <div class="pages">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="pageIndex"
            :page-size="20"
            @current-change="currentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "./components/banner";
import share from "@/components/share";
export default {
  components: { banner, share },
  data() {
    return {
      yz_vote_year: [],
      year: { dictLabel: "年份" },
      yz_university: [],
      university: { dictLabel: "高校" },
      yz_vote_award: [],
      yz_vote_award_type: [],
      award: { dictLabel: "奖项" },
      type: { dictLabel: "类别" },
      pageIndex: 1,
      pageSize: 20,
      total: 0,
      datalist: [],
      loading: 0,
    };
  },
  methods: {
    yChange(val) {
      if (val != "-1") {
        this.year = this.yz_vote_year.find((item) => item.dictValue == val);
      } else {
        this.year = { dictLabel: "年份" };
      }
      this.currentChange(1);
    },
    gChange(val) {
      if (val != "-1") {
        this.university = this.yz_university.find(
          (item) => item.dictValue == val
        );
      } else {
        this.university = { dictLabel: "高校" };
      }
      this.currentChange(1);
    },
    lChange(val) {
      if (val != "-1") {
        this.type = this.yz_vote_award_type.find(
          (item) => item.dictValue == val
        );
      } else {
        this.type = { dictLabel: "类别" };
      }
      this.currentChange(1);
    },
    jChange(val) {
      if (val != "-1") {
        this.award = this.yz_vote_award.find((item) => item.dictValue == val);
      } else {
        this.award = { dictLabel: "奖项" };
      }
      this.currentChange(1);
    },
    getData() {
      this.loading = 1;
      this.$api
        .post("/api/v1/getStudentInfoList", {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          year: this.year.dictValue || "",
          type: this.type.dictValue || "",
          schoolId: this.university.dictValue || "",
          awardsType: this.award.dictValue || "",
        })
        .then((res) => {
          this.datalist = res.data.list;
          this.total = res.data.count;
          if (this.datalist.length) {
            this.loading = 0;
          } else {
            this.loading = 2;
          }
          this.$nextTick(() => {
            this.$store.commit("anime");
          });
        });
      // if (
      //   this.year.dictLabel == "年份" &&
      //   this.university.dictLabel == "高校" &&
      //   this.award.dictLabel == "奖项" &&
      //   this.type.dictLabel == "类别"
      // ) {
      //   this.$sapi
      //     .get(`/api/v1/getStudentInfoList${this.pageIndex}.json`)
      //     .then((res) => {
      //       this.datalist = res.list;
      //       this.total = res.count;
      //       if (this.datalist.length) {
      //         this.loading = 0;
      //       } else {
      //         this.loading = 2;
      //       }
      //       this.$nextTick(() => {
      //         this.$store.commit("anime");
      //       });
      //     });
      // } else {

      // }
    },
    getList() {},
    currentChange(val) {
      this.pageIndex = val;
      this.datalist = [];
      this.getData();
    },
  },
  mounted() {
    //年份
    this.$sapi.get("/api/v1/getDictDataList-yz_vote_year.json").then((res) => {
      this.yz_vote_year = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
    });
    //高校
    this.$sapi.get("/api/v1/getDictDataList-yz_university.json").then((res) => {
      this.yz_university = [
        { dictValue: "-1", dictLabel: "全部" },
        ...res.filter((item) => {
          return item.treeLevel != 0;
        }),
      ];
    });
    //类别
    this.$sapi
      .get("/api/v1/getDictDataList-yz_vote_award_type.json")
      .then((res) => {
        this.yz_vote_award_type = [
          { dictValue: "-1", dictLabel: "全部" },
          ...res,
        ];
      });
    //奖项
    this.$sapi.get("/api/v1/getDictDataList-yz_vote_award.json").then((res) => {
      this.yz_vote_award = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
    });
    this.getData();
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src =
    //   "http://static.bshare.cn/b/buttonLite.js#style=-1&amp;uuid=&amp;pophcol=2&amp;lang=zh";
    // document.getElementsByTagName("head")[0].appendChild(script);
    // let script2 = document.createElement("script");
    // script2.type = "text/javascript";
    // script2.src = "http://static.bshare.cn/b/bshareC0.js";
    // document.getElementsByTagName("head")[0].appendChild(script2);
    // script.onload = function () {
    //   $(".show-list .list .item").each(function () {
    //     bShare.addEntry({
    //       title: $(this).find(".info h3").html(), // 获取文章标题
    //       url: `https://www.yizao2025.com/competition/showDetail?id=${$(
    //         this
    //       ).attr("data-id")}`, // 获取文章链接
    //       summary: $(this).attr("data-desc"), // 从postBody中获取文章摘要
    //       pic: $(this).find(".info img").length
    //         ? $(this).find(".info img").attr("src")
    //         : "",
    //     });
    //   });
    // };
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 4vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .show-list {
    margin-top: 5vw;
    .filter {
      display: flex;
      justify-content: space-around;
      margin-top: 4vw;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2vw;
      .item {
        width: 23%;
        margin: 3% 1%;
        text-align: center;
        color: #fefeff;
        .el-image {
          width: 100%;
          height: 275px;
          display: block;
          margin: 0 auto;
        }
        .info {
          width: 100%;
          margin: 0 auto;
          box-sizing: border-box;
          padding: 15px;
          background-color: #333333;
          h3 {
            @include line();
            font-size: 14px;
            text-align: left;
          }
          div.b {
            margin-top: 10px;
            display: flex;
            align-items: center;
            p {
              display: flex;
              align-items: center;
              color: fade-out($color: #fefeff, $amount: 0.5);
              font-size: 14px;
              width: 25%;
              &:last-child {
                justify-content: flex-end;
                color: $c2;
              }
              i {
                font-size: 14px;
                margin-right: 5px;
              }
            }
            .bshare-custom {
              width: 25%;
              text-align: right;
              justify-content: flex-end;
              a {
                &:hover {
                  text-decoration: none;
                }
                background-image: none;
                i.iconfont {
                  color: $c2;
                }
              }
            }
          }
        }
      }
    }
  }
  .filter-list {
    max-height: 300px;
    overflow-y: auto;
  }
  .pages {
    text-align: center;
    padding: 30px 0;
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
    .el-pagination button:disabled {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .loading {
    i {
      color: white;
    }
  }
}
</style>